import React, { useContext, useRef, useEffect } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"
import SEO from "../components/seo"
import { Script } from "gatsby"

import Grid from "../components/partnerships/grid"
import MiddleText from "../components/fintech/middle-text"
import ActionButton from "../components/action-button"
import GreenCircle from "../components/green-circle"
import EverySolution from "../components/partnerships/every-solution"
import GlobalContext from "../context/global-context"
import loadable from '@loadable/component'
import Fintech from '../assets/images/fintech_background.svg'
import Travel from '../assets/images/travel_background.svg'
import Igaming from '../assets/images/igaming_background.svg'
import Ecom from '../assets/images/ecom.svg'
import Health from '../assets/images/health.svg'
import Edtech from '../assets/images/edtech.svg'
import Social from '../assets/images/social.svg'
import Subsc from '../assets/images/subsc.svg'
import Gateway from "../components/partnerships/gateway"
import Partnership from "../components/partnerships/partnership"
import Modal from "../components/modal"

const HeaderAnimation = loadable(() => import("../components/partnerships/slot-machine"))
const Card = loadable(() => import("../components/index/card"))
const FastComponent = loadable(() => import("../components/common/partners"))


const CustomActionButton = styled(ActionButton)`
  width: 263px;
  margin: 20px auto;
  z-index: 20;
`



const FeaturesContainer = styled.div`
grid-area: features-container;
width: 1010px;
display: flex;
justify-content: space-between;
margin-top: 30px;


@media screen and (max-width: 980px) {
  flex-direction: column;
  width: 523px;
  align-items: center;
}

@media screen and (max-width: 640px) {
  width: 100%;

}
`

const H4 = styled.h4`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
    margin-bottom: -15px;
  }
`

const Feature = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 330px;
  z-index: 1;

  p {
    font-size: 20px;
    line-height: 30px;
    margin-left: 24px;
    text-align: left;
  }

  @media screen and (max-width: 980px) {
    flex-direction: row;
    margin-bottom: 40px;

    p {
      text-align: left;
      flex: 1;
      font-size: 18px;
      line-height: 28px;
      margin-top: 0;
    }
  }

  @media screen and (max-width: 640px) {
    width: 280px;
  }
`
const ImageComtainer = styled.div`
  width: 40px;
  height: 40px;
`

const CTAText = styled(H4)`
  margin-top: 30px;
  text-align: center;

  @media screen and (max-width: 980px) {
    text-align: center;

  }

  @media screen and (max-width: 640px) {
    text-align: center;
  margin-bottom: 10px;


  }
`

const TalkToSales = styled.div`
grid-area: ${props => props.gridArea};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const More = styled.div`
grid-area: more;
font-weight: 800;
font-size: 24px;
line-height: 30px;
flex-direction: column;
justify-content: center;
display: flex;
height: 100%;

@media screen and (max-width: 980px) {


  display: none;

}

@media screen and (max-width: 640px) {
  margin-top: 30px;
  text-align: center;
  display: none;
}
`

const CustomModal = styled(Modal)`

iframe{
  width: 100%;
  height: 100%;
  border:none;
}
`
export default function IGamingPage({ location }) {
  const dispatch = useDispatch()
  const modalRef = useRef()
  const globalContext = useContext(GlobalContext)
  const version = 1

  useEffect(() => {
    if (typeof window.hbspt !== 'undefined') {
      window.hbspt.forms.create({
        region: "na1",
        portalId: "5855020",
        formId: "fcce2c0b-9657-4861-a591-7142becc0c1d"
      });
    } else {
      console.error("HubSpot script failed to load");
    }
  }, []);

  return (
    <>
      <SEO
        title="Partner up and grow together with us"
        description="Expand your offering and enable faster growth for your customers by joining the world leading solution for signup, identity and login. "
        canonical="/botlens/"
        ogImage={require("/src/assets/images/og/og-startpage-min.png").default}
      />

      <Grid version={version}>
        <MiddleText
          h1
          primary
          gridArea="header"
          headerText="Partner up and grow<br/> together with us"
          subheaderText="Expand your offering and enable faster growth for your customers by joining the world leading solution for signup, identity and login. "
        />

        <HeaderAnimation />

        <FeaturesContainer>
          <Feature>
            <ImageComtainer>
              <GreenCircle />
            </ImageComtainer>
            <p>Offer the optimal <br />
              KYC for every industry</p>
          </Feature>
          <Feature>
            <ImageComtainer>
              <GreenCircle />
            </ImageComtainer>
            <p>Cover all KYC needs with <br />
              a single solution</p>
          </Feature>
          <Feature>
            <ImageComtainer>
              <GreenCircle />
            </ImageComtainer>
            <p>Easy and flexible <br /> integration to any system</p>
          </Feature>
        </FeaturesContainer>


        <TalkToSales gridArea="talk-to-sales-1">
          <CustomActionButton
            className="hs-cta-trigger-button hs-cta-trigger-button-162913511979"

          >
            Let's talk!
          </CustomActionButton>
        </TalkToSales>

        <MiddleText
          gridArea="why-header"
          headerText="Why partner <br/>with Checkin.com?"
        />

        <EverySolution gridArea="why" />


        <MiddleText
          gridArea="optimal"
          headerText="Optimal KYC for every regulated industry and market"
          subheaderText="We understand the unique challenges and regulatory landscapes, crafting solutions that are as diverse as the sectors we serve."
        />
        <Card
          href="/industry-fintech"
          className="fintech"
          background={Fintech}
          header='Fintech & Crypto'
          text='Help your customers avoid fines, secure their data and help them make complex applications easy to complete'
          gridArea='fintech' />
        <Card
          href="/industry-travel"
          className="travel"
          background={Travel}
          header="Travel"
          text="Help your customers replace outdated processes and increase security with automated customer onboarding"
          gridArea='travel' />
        <Card
          href="/industry-igaming"
          className="igaming"
          background={Igaming}
          header="iGaming"
          text="Make sure your customers comply with local gambling regulation and increase conversion with features tailored to iGaming brands"
          gridArea='igaming' />

        <More>
          And more...
        </More>
        <Card
          href="/#"
          className="small ecom"
          background={Ecom}
          header="E-commerce"
          gridArea='ecom' />
        <Card
          href="/#"
          className="small"
          background={Social}
          header="Social media"
          gridArea='social' />
        <Card
          href="/#"
          className="small subsc"
          background={Subsc}
          header="Subscriptions"
          gridArea='subsc' />
        <Card
          href="/#"
          className="small edtech"
          background={Edtech}
          header="Edtech"
          gridArea='edtech' />
        <Card
          href="/#"
          className="small health"
          background={Health}
          header="Healthcare"
          gridArea='health' />

        <Gateway />
        <FastComponent gridArea="partners" />

        <MiddleText
          gridArea="partnership-header"
          headerText="Which type of partnership are you looking for?"
        />

        <Partnership modalRef={modalRef} />


      </Grid >
      <CustomModal vhHeight="95vh" ref={modalRef}>
        <iframe src="https://share.hsforms.com/1_M4sC5ZXSGGlkXFCvswMHQ3hhrg" title="hubspot"></iframe>
      </CustomModal>
      <Script strategy="idle" src="//js.hsforms.net/forms/embed/v2.js"></Script>
    </>
  )
}
