import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import styled from "styled-components"

import { detectDevice } from "../state/actions"

const Container = styled.div`
  display: flex;
  flex: 1;
  background: #f9f9f8;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 640px) {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    overflow: hidden;
  }
`

export default function CalendarLayout({ children }) {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(detectDevice())
  }, [dispatch])

  return <Container>{children}</Container>
}
