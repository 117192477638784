import React, { useState, useRef } from "react"
import styled, { keyframes, css } from "styled-components"

import SEO from "../../components/seo"
import Calendar from "../../components/meeting-booker/calendar"
import GreenArrow from "../../assets/images/bold_arrow.inline.svg"
import GreenCircle from "../../components/green-circle"
import ArrowSquare from "../../assets/images/meeting-booker/arrow-square.inline.svg"
import Kindred from "../../assets/images/kindred2.svg"
import Modal from "../../components/modal"
import ActionButton from "../../components/action-button"
import Admiral from "../../assets/images/fintech/admiral.svg"
import Decta from "../../assets/images/fintech/decta.svg"
import Rayanair from "../../assets/images/rayanair.svg"
import DectaPartner from "../../assets/images/decta.svg"
import Borica from "../../assets/images/borica.svg"
import Casumo from "../../assets/images/casumo.svg"
import Pacaso from "../../assets/images/pacaso.svg"
import Playtech from "../../assets/images/playtech.svg"
import Maxer from "../../assets/images/maxer.svg"
import Document from "../../assets/images/ocr/document.inline.svg"
import Compliance from "../../assets/images/ocr/compliance.inline.svg"
import Extract from "../../assets/images/ocr/extract.inline.svg"
import LivenessCheck from "../../assets/images/ocr/liveness.inline.svg"
import Line from "../../assets/images/line_dotted.svg"



const fadeInOut = keyframes`
  0%   {opacity: 1;}
  50%  {opacity: 0;}
  100%  {topacityop: 1;}
`

const Container = styled.div`
  display: flex;
  flex: 1;
  width: 1170px;
  box-sizing: border-box;
  padding: 135px 0 0;
  flex-direction: column;
  align-self: center;
  align-items: center;
  position: relative;

  @media screen and (max-width: 980px) {
    padding: 50px 0 0;
    width: 632px;
  }

  @media screen and (max-width: 640px) {
    width: 345px;
  }

`

const Section = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 45px;

  ${props =>
    props.column &&
    css`
      flex-direction: column;

    `}

    @media screen and (min-width: 1366px) {
    &.calendar {
      width: 111%;
    }
    }

  @media screen and (max-width: 980px) {
    flex-direction: column;
    margin-bottom: 30px;
    align-items: center;

    &.calendar {
      width: 110%;
    }
  }

  @media screen and (max-width: 640px) {
    &.calendar {
      width: 105%;
    }
  }
`



export const H2 = styled.h2`
  font-size: 42px;
  line-height: 52px;
  font-weight: 800;
  margin-bottom: 16px;

  @media screen and (max-width: 980px) {
    font-size: 40px;
    line-height: 50px;
    text-align: center;
    font-weight: 800;
  }

  @media screen and (max-width: 640px) {
    text-align: left;
    font-weight: 800;
    font-size: 34px;
    line-height: 44px;
  }
`

export const H4 = styled.h2`
  font-size: 34px;
  line-height: 42px;
  font-weight: 800;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
  }
`

export const H6 = styled.h2`
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
  }
`

const SideBar = styled.div`
  display: flex;
  width: 40%;
  height: 700px;
  flex-direction: column;
  padding-right: 23px;
  padding-top: 20px;

  p {
    font-size: 20px;
    line-height: 30px;
  }

  @media screen and (max-width: 980px) {
    width: 100%;
    height: auto;

    p {
      text-align: center;
      br {
        display: none;
      }
    }
  }

  @media screen and (max-width:640px) {
    padding-top: 5px;
    margin-bottom: 25px;
    p{
     text-align: left;
    }
  }
}


`

const FeaturesContainer = styled.div`
  display: flex;
  margin-top: 40px;
  justify-content: space-around;
  @media screen and (max-width: 980px) {
    display: none;
  }
`

const CustomFeaturesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;


`

const FeaturesContainerMobile = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  display: none;

  @media screen and (max-width: 980px) {
    display: block;
  }
`


const Feature = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  z-index: 1;
  margin-bottom: 26px;

  p {
    font-size: 18px;
    line-height: 30px;
    margin-left: 24px;
    text-align: left;
  }

  @media screen and (max-width: 980px) {
    flex-direction: row;
    width: 500px;
    margin: 0 auto;
    margin-bottom: 40px;


    p {
      text-align: left;
      flex: 1;
      font-size: 18px;
      line-height: 28px;
      margin-top: 0;
    }

    br {
      display: none;
    }
  }

  @media screen and (max-width: 640px) {
    width: 310px;
    p {
      margin-left: 0px;
    }
  }
`

const CustomFeature = styled(Feature)`
margin-bottom: 15px;
p {
  font-weight: 700;
  font-size: 18px;
  line-height: 26px;
}
@media screen and (max-width: 640px){
  width: 370px;
  p {
    margin-left: 4px;
  }
}
`
const ImageComtainer = styled.div`

`

const ImageContainerRight = styled.div`
  background-color: white;
  position: relative;
  left: 30px;
  height: 57px;
  @media screen and (max-width: 640px){
      position: absolute;
      left: unset;
      right: -27px;
      top: 26px;
      width: 57px;
  }
`

const ImageContainerLeft = styled.div`
  background-color: white;
  position: relative;
  right: 30px;
  height: 57px;

  @media screen and (max-width: 640px){
    position: absolute;
    width: 57px;
    top: 26px;
    left: -27px;
    right: unset;

  }

`


const QuoteContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto; 
  border: 1px solid;
  margin-bottom: 50px;
  background-color: white;
  @media screen and (max-width: 980px) {
    width: 100%;
  }
  @media screen and (max-width: 640px) {
    position: relative;
    width: 90%;
  }

`

const PartnerQuote = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  height: 238px;

  &.fadeInOut{
    animation: ${fadeInOut} 0.7s ease forwards;
  }

  @media screen and (max-width: 980px) {
    font-weight: 400;
    font-size: 14px;
    line-height: 26px;
    height: 315px;

  }

  @media screen and (max-width: 640px) {
    flex-direction: column;
    background: white;
    padding: 32px 21px;
    height: 366px;
  }
`
const PartnerLogo = styled.div`
  width: 410px;
  background: #fff;
  height: 133px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 36px;
  position: relative;

  @media screen and (max-width: 980px) {
    width: 204px;
    height: 32px;
    margin-right: 20px;

    img {
      width: 100%;
    }
  }
  
`
const Quote = styled.div`
  width: 515px;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  @media screen and (max-width: 980px) {
    width: 346px;
  }
  @media screen and (max-width: 640px) {
    width: auto;
    margin-top: 30px;
    font-size: 16px;
    line-height: 24px;
  }
`

const Right = styled(ArrowSquare)`
  box-shadow: 5px 5px 0px rgb(0 0 0 / 10%);
  position: relative;

  &.disabled {
    opacity: 0.3;
  }

  @media screen and (max-width: 640px) {
    position: absolute;
    z-index: 11;
    left: unset;
  }
`

const Left = styled(ArrowSquare)`
  position: relative;
  transform: rotate(180deg);
  box-shadow: 5px -5px 0px rgb(0 0 0 / 10%);

  &.disabled {
    opacity: 0.3;
  }

  @media screen and (max-width: 640px) {
    position: absolute;
    z-index: 11;
  }

`
const CustomModal = styled(Modal)`

${H2}{
  width : 600px;
  text-align: center;
  margin-bottom: 5px;

  @media screen and (max-width: 980px) {
    font-weight: 800;
    font-size: 30px;
    line-height: 38px;
  }

  @media screen and (max-width: 460px) {
    display: none;
  }

}

`

const CustomCalendar = styled(Calendar)`


.meetings-iframe-container iframe {
    height: 100% !important;
}
@media screen and (max-width: 460px) {
  .meetings-iframe-container {
    top: -34px !important;
  }
}
`

const CTAText = styled(H4)`
  text-align: center;
  br {
    display: none;
  }

  @media screen and (max-width: 980px) {
    width: 389px;
  }

  @media screen and (max-width: 640px) {
    width: 333px;
    br {
      display: block;
    }
  }
`



const TalkToSalesButton = styled(ActionButton)`
  width: 264px;
  margin-top: 30px;
  font-size: 18px;

  @media screen and (max-width: 980px) {
    margin: 0 auto;
    margin-top: 30px;
  }
`

const Sectionheader = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.primary &&
    css`
      margin-bottom: 40px;

      @media screen and (max-width: 980px) {
        margin-bottom: 20px;
      }
    `}
`

const PartnerContainer = styled.div`

  width: 207px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
  }

`

const Row = styled.div`
  align-self: center;
  width: 1012px;
  display: flex;
  justify-content: space-between;

`

const FeaturesContainer2 = styled.div`

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 60px;
  margin-top: 20px;

@media screen and (max-width: 980px) {
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  width: 296px;
  flex-direction: column;

  ${ImageComtainer} {
    width: auto;
  }
}

`
const FeaturesColumn = styled.div`
`

const Feature2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 339px;
  z-index: 1;
  margin-top: 40px;
  text-align: center;

  @media screen and (max-width: 980px) {
    flex-direction: column !important;
  }

  @media screen and (max-width: 640px) {
    align-items: center;
  }
  

  ${H6} {
    margin-bottom: 16px;
  }

  p {
    font-size: 16px;
    line-height: 28px;

    @media screen and (max-width: 980px) {
      width: 278px;
      text-align: left;
      font-size: 16px !important;
      line-height: 28px !important;

    }
    
  }

  ${ImageComtainer} {
    height: 90px;
    justify-content: center;
    display: flex;
  }

  }
  @media screen and (max-width: 980px) {
    flex-direction: row;
    width: 100%;
    margin-bottom: 40px;

    p {
      text-align: left;
      flex: 1;
      font-size: 18px;
      line-height: 28px;
      margin-top: 0;
    }
  }

  @media screen and (max-width: 640px) {
    p{
    text-align: center !important;
    }
  }


`

const PartnerRows = styled.div`
  margin: 0 auto; 
  margin-bottom: 100px;
  @media screen and (max-width: 640px){
    display: none;
  }
`

const PartnerRowsMobile = styled.div`
@media screen and (min-width: 641px){
  display: none;
}

${Row} {
  width: auto;
  height: 55px;
}
`
const CalendarContainer = styled.div`
   @media screen and (max-width: 640px){
    display: none;
   }
`
const MobileContent = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
    p {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 30px;
      text-align: center;
      letter-spacing: 1.5px;
      text-transform: uppercase;
    }
    @media screen and (min-width: 641px){
      display: none;
    }
`

export default function MeetingBooker({ location }) {

  const [selectedPartner, setSelectedPartner] = useState(0)
  const [fadeInOut, setFadeInOut] = useState(false)
  const debug = new URLSearchParams(location.search).get("debug") === 'true' ? true : false;

  const PartnerQuotes = [
    {
      logo: Admiral,
      quote: `
      <p>"While even more important we have been able to open up new countries as a result of our ability to successfully verify clients from countries where previously we did not have such an option."</p>
      <br/>
      <p><b>Andreas Ioannou</b>, Head of Global Compliance</p>
      `
    },
    {
      logo: Decta,
      quote: `
      <p>“As the pace of life is accelerating, and as a company that provides a licensed product, we must speed up all verification processes. Together with Checkin.com, we’ve been able to achieve that.”</p>
      <br/>
      <p><b>Jevgenijs Godunovs</b>, Director, MLRO</p>
      `
    },
    {
      logo: Kindred,
      quote: `
      <p>"With Checkin.com, the player registration process will not only be more interactive but also help us to understand the player better from the point of signup"</p>
      <br/>
      <p><b>Trille Levin</b>, Experience Owner</p>
      `
    }
  ]

  const modalRef = useRef()

  async function Next() {

    if (selectedPartner < PartnerQuotes.length - 1) {
      setFadeInOut(true)
      setTimeout(() => {
        setFadeInOut(false)
      }, 700)
      setTimeout(() => {
        setSelectedPartner(selectedPartner + 1)
      }, 200)
    }
  }

  function Previous() {
    if (selectedPartner > 0) {
      setFadeInOut(true)
      setTimeout(() => {
        setFadeInOut(false)
      }, 700)
      setTimeout(() => {
        setSelectedPartner(selectedPartner - 1)
      }, 200)
    }
  }

  const Logo = () => {
    const Icon = PartnerQuotes[selectedPartner].logo
    return <Icon />
  }

  return (
    <Container>
      <SEO
        title="Schedule time with an onboarding expert here"
        description="Book time directly to discuss your use-case and get a complete solution for your KYC and customer onboarding challenges."
        canonical="/meeting-booker/"
        ogImage={require("/src/assets/images/og/og-startpage-min.png").default}
      />
      <Section className="calendar">
        <SideBar>
          <H2>
            Increase conversion with compliant signup flows
          </H2>
          <p>
            Let Checkin.com solve the toughest challenges related to identifying and converting customers in regulated markets.
          </p>
          <CustomFeaturesContainer>
            <CustomFeature>
              <ImageComtainer>
                <GreenArrow />
              </ImageComtainer>
              <p>All KYC solutions in one place</p>
            </CustomFeature>
            <CustomFeature>
              <ImageComtainer>
                <GreenArrow />
              </ImageComtainer>
              <p>Developer-friendly integration</p>
            </CustomFeature>
            <CustomFeature>
              <ImageComtainer>
                <GreenArrow />
              </ImageComtainer>
              <p>Backoffice for compliance management</p>
            </CustomFeature>
            <CustomFeature>
              <ImageComtainer>
                <GreenArrow />
              </ImageComtainer>
              <p>Fast & reliable identity verification</p>
            </CustomFeature>
            <CustomFeature>
              <ImageComtainer>
                <GreenArrow />
              </ImageComtainer>
              <p>Cost-efficient & scalable solution</p>
            </CustomFeature>
          </CustomFeaturesContainer>

          <TalkToSalesButton onClick={() => modalRef.current.open(null, null, null, null, true)}>
            Get started
          </TalkToSalesButton>

        </SideBar>
        <Calendar
          debug={debug}
          height="793px"
        />

      </Section>
      <Section column>
        <Sectionheader primary>
          <H4>
            Built for fast-growing companies
          </H4>
        </Sectionheader>

        <QuoteContainer>
          <ImageContainerLeft>
            <Left onClick={() => Previous()} className={selectedPartner == 0 ? 'disabled' : null} />
          </ImageContainerLeft>

          <PartnerQuote className={fadeInOut ? "fadeInOut" : null} >
            <PartnerLogo>
              <img src={PartnerQuotes[selectedPartner].logo} />
            </PartnerLogo>
            <Quote
              dangerouslySetInnerHTML={{ __html: PartnerQuotes[selectedPartner].quote }}
            >
            </Quote>
          </PartnerQuote>

          <ImageContainerRight>
            <Right onClick={() => Next()} className={selectedPartner == 2 ? 'disabled' : null} />
          </ImageContainerRight>
        </QuoteContainer>

        <PartnerRows>
          <Row>
            <PartnerContainer>
              <img src={Rayanair} style={{ width: "206px", height: "25px" }} />
            </PartnerContainer>
            <PartnerContainer>
              <img src={Borica} style={{ width: "190px", height: "37px" }} />
            </PartnerContainer>
            <PartnerContainer>
              <img src={DectaPartner} style={{ width: "139px", height: "28px" }} />
            </PartnerContainer>
            <PartnerContainer>
              <img src={Casumo} style={{ width: "130px", height: "29px" }} />
            </PartnerContainer>
          </Row>

          <br />
          <br />

          <Row>
            <PartnerContainer>
              <img src={Kindred} style={{ width: "207px", height: "69px" }} />
            </PartnerContainer>
            <PartnerContainer>
              <img src={Pacaso} style={{ width: "138px", height: "28px" }} />
            </PartnerContainer>
            <PartnerContainer>
              <img src={Playtech} style={{ width: "191px", height: "50px" }} />
            </PartnerContainer>
            <PartnerContainer>
              <img src={Maxer} style={{ width: "127px", height: "20px" }} />
            </PartnerContainer>
          </Row>
        </PartnerRows>

        <PartnerRowsMobile>
          <Row>
            <PartnerContainer>
              <img src={Rayanair} style={{ width: "150px", height: "18px" }} />
            </PartnerContainer>
            <PartnerContainer>
              <img src={Pacaso} style={{ width: "100px", height: "20px" }} />
            </PartnerContainer>
          </Row>

          <Row>
            <PartnerContainer>
              <img src={Borica} style={{ width: "130px", height: "23px" }} />
            </PartnerContainer>
            <PartnerContainer>
              <img src={Decta} style={{ width: "101px", height: "20px" }} />
            </PartnerContainer>
          </Row>

          <Row>
            <PartnerContainer>
              <img src={Kindred} style={{ width: "150px", height: "50px" }} />
            </PartnerContainer>
            <PartnerContainer>
              <img src={Playtech} style={{ width: "139px", height: "36px" }} />
            </PartnerContainer>
          </Row>
          <br />
          <br />
          <br />
        </PartnerRowsMobile>

        <img src={Line} />
        <FeaturesContainer2>
          <Feature2>
            <ImageComtainer>
              <Document />
            </ImageComtainer>
            <H6>Scan any ID or document</H6>
            <p>Online verification of ID cards, passports, driver’s licenses, and residence permits. Over 8,000 document types supported.</p>
          </Feature2>
          <Feature2>
            <ImageComtainer>
              <Compliance />

            </ImageComtainer>
            <H6>AML & KYC checks</H6>
            <p>Check customers against all global law enforcement databases, sanctions lists and PEP lists, and other sources.</p>
          </Feature2>
          <Feature2>
            <ImageComtainer>
              <LivenessCheck />

            </ImageComtainer>
            <H6>Face matching & liveness</H6>
            <p>Let customers take selfies or perform live actions with their camera to instantly verify it’s a real person that matches their ID. </p>
          </Feature2>

        </FeaturesContainer2>
        <img src={Line} />


        <CustomModal
          ref={modalRef}
        >
          <H2>Get a live demo and sandbox access</H2>
          <CustomCalendar
            className="modalCalendar"
            height='860px'
            width='100%'
            debug={debug}
          />
        </CustomModal>
      </Section>



      <Section>
        <div style={{ width: "100%", marginTop: "50px", marginBottom: "150px" }}>
          <Sectionheader primary>
            <H4>
              Get a live demo and sandbox access
            </H4>
          </Sectionheader>
          <CalendarContainer>

            <Calendar
              height="793px" />
          </CalendarContainer>

          <MobileContent>
            <TalkToSalesButton onClick={() => modalRef.current.open(null, null, null, null, true)}>
              Get started
            </TalkToSalesButton>
            <p>Conversion increase guaranteed</p>
          </MobileContent>




          <FeaturesContainer>
            <Feature>
              <ImageComtainer>
                <GreenCircle />
              </ImageComtainer>
              <p>Get a full list of supported<br />
                checks, geos and documents</p>
            </Feature>
            <Feature>
              <ImageComtainer>
                <GreenCircle />
              </ImageComtainer>
              <p>Get a full list of supported<br />
                checks, geos and documents </p>
            </Feature>
            <Feature>
              <ImageComtainer>
                <GreenCircle />
              </ImageComtainer>
              <p>Test the improved<br /> onboarding yourself</p>
            </Feature>
          </FeaturesContainer>
        </div>
      </Section>

    </Container >
  )
}