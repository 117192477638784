import React from "react"
import styled from "styled-components"


const H4 = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;

  ${props => props.headerFontSize && `
    font-size: ${props.headerFontSize};
  `}

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
  }
`

const Feature = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  margin-top: 40px;

  @media screen and (max-width: 980px) {
    flex-direction: column !important;
  }

  @media screen and (max-width: 640px) {
    align-items: center;
  }
  

  ${H4} {
    margin-bottom: 16px;
    margin-top: 15px;
  }

  p {
    font-size: 16px;
    line-height: 30px;

    @media screen and (max-width: 980px) {
      width: 278px;
      text-align: left;
      font-size: 16px !important;
      line-height: 28px !important;

    }
    
  }

  

  @media screen and (max-width: 980px) {
    flex-direction: row;
    width: 100%;
    margin-bottom: 40px;

    p {
      text-align: left;
      flex: 1;
      font-size: 18px;
      line-height: 28px;
      margin-top: 0;
    }
  }

  @media screen and (max-width: 640px) {
    p{
    text-align: center !important;
    }
  }


`

const ImageComtainer = styled.div`
  height: 80px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 640px) {
    height: auto;
  }
`

const GridContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;

  ${props =>
    props.gridTemlplateCol && `
    grid-template-columns: ${props.gridTemlplateCol};
      `}
  
  ${props =>
    props.justify && `
    justify-content: ${props.justify};
      `}

  ${props =>
    props.rowGap && `
    row-gap: ${props.rowGap};
      `}

  ${props =>
    props.columnGap && `
    column-gap: ${props.columnGap};
      `}
      

  ${props =>
    props.width && `
        width: ${props.width};
      `}


  @media screen and (max-width: 460px) {
    grid-template-columns: 1fr;
    row-gap: unset;
  }
`

export default function GridElements({
  data,
  width,
  rowGap,
  columnGap,
  gridTemlplateCol,
  headerStyle,
  justify,
  featureStyle
}) {

  return (

    <GridContainer
      rowGap={rowGap}
      columnGap={columnGap}
      width={width}
      gridTemlplateCol={gridTemlplateCol}
      justify={justify}

    >
      {data.map((element, index) => (
        <Feature key={index} style={featureStyle}>
          <ImageComtainer>
            {element.image}
          </ImageComtainer>
          <H4 style={headerStyle}>{element.title}</H4>
          <p>{element.text}</p>
        </Feature>
      ))}
    </GridContainer >
  )
}
