import React, { useContext } from "react"
import styled, { css, keyframes } from "styled-components"
import { Link } from "gatsby"


import LivenessCheck from "../../assets/images/ocr/liveness.inline.svg"
import Poa from "../../assets/images/travel/poa.inline.svg"
import Ocr from "../../assets/images/fintech/ocr.inline.svg"
import Verify from "../../assets/images/fintech/verify.inline.svg"
import Liveness from "../../assets/images/fintech/liveness.inline.svg"
import Kyc from "../../assets/images/fintech/kyc.inline.svg"
import ActionButton from "../../components/action-button"
import GlobalContext from "../../context/global-context"




const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  text-align: left;
  justify-content: center;
  grid-area: ${props => props.gridArea};
  align-items: center;
  font-size: 20px;
  line-height: 30px;
  z-index: 1;

  &:last-child {
    margin-bottom: 80px;
  }
  &#delivery, &#fast-easy {
    @media screen and (max-width: 640px) {
      margin-bottom: 0px;
    }
  }

`

const H2 = styled.h2`
  font-size: 34px;
  line-height: 42px;
  font-weight: 900;
  margin-bottom: 16px;

  

  @media screen and (max-width: 980px) {
    font-size: 28px;
    line-height: 32.2px;
    br {
      display: none;
    }
  }
`

const H4 = styled.h2`
  font-size: 24px;
  line-height: 36px;
  font-weight: 700;

  @media screen and (max-width: 980px) {
    font-size: 20px;
    line-height: 32px;
    font-weight: 700;
  }

  @media screen and (max-width: 640px) {
    font-size: 20px;
    line-height: 30px;
    font-weight: 900;
  }
`

const Sectionheader = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  ${props =>
    props.primary &&
    css`
      margin-bottom: 40px;

      @media screen and (max-width: 980px) {
        margin-bottom: 20px;
      }
    `}
`
const SectionDescription = styled.p`
  font-size: 20px;
  line-height: 30px;

  @media screen and (max-width: 980px) {
    font-size: 18px;
    line-height: 32px;
  }
  @media screen and (max-width: 640px) {
    br {
      display: none;
    }
  }
`

const SectionDescription1 = styled(SectionDescription)`
  width: 679px;

  @media screen and (max-width: 980px) {
    width: 578px;
  }

  @media screen and (max-width: 640px) {
    width: 334px;
  }
`

const FeaturesContainer2 = styled.div`
display: flex;
justify-content: space-around;
width: 100%;
margin-bottom: 50px;
flex-direction: column;


@media screen and (max-width: 980px) {
  justify-content: space-between;
}

@media screen and (max-width: 640px) {
  width: 296px;
  flex-direction: column;
}

`
const FeaturesColumn = styled.div`
display: flex;
justify-content: inherit;
@media screen and (max-width: 640px) {
  flex-direction: column;
}
`

const Feature2 = styled.div`
  display: flex;
  flex-direction: column;
  width: 330px;
  z-index: 1;
  margin-top: 40px;

  @media screen and (max-width: 980px) {
    flex-direction: column !important;
  }

  @media screen and (max-width: 640px) {
    align-items: center;
  }
  

  ${H4} {
    margin-bottom: 16px;
    margin-top: 15px;
  }

  p {
    font-size: 20px;
    line-height: 30px;
    width: 424px;

    @media screen and (max-width: 980px) {
      width: 278px;
      text-align: left;
      font-size: 16px !important;
      line-height: 28px !important;

    }
    
  }

  

  @media screen and (max-width: 980px) {
    flex-direction: row;
    width: 100%;
    margin-bottom: 40px;

    p {
      text-align: left;
      flex: 1;
      font-size: 18px;
      line-height: 28px;
      margin-top: 0;
    }
  }

  @media screen and (max-width: 640px) {
    p{
    text-align: center !important;
    }
  }


`

const ImageComtainer = styled.div`
  height: 80px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  @media screen and (max-width: 640px) {
    height: auto;
  }
`

const Buttons = styled.div`
  display: flex;
  width: 55%;
  justify-content: space-around;
  bottom: 0px;

  

  @media screen and (max-width: 980px) {
    width: 100%;
  }

  @media screen and (max-width:640px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
    position: relative;


    button, a {
      width: 100%;
    }
  }
`

const FeaturesButton = styled(ActionButton)`
  background: #fff;
  color: #000;
  border: 1px solid #000;
  outline: none;
  box-shadow: none;
  padding: 18px 40px;
  font-size: 18px;
  font-weight: 700;
  margin-top:30px;
  cursor: pointer;
  position: relative;

  &::after {
    content: "";
    border: 1px solid #A5A5A5;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 5px ;
    left: 5px ;
    z-index: -1;
  }

  &:hover {
    box-shadow: 5px 5px 0 0 #000;
  }
`

const TalkToSalesButton = styled(ActionButton)`
  width: 264px;
  margin-top: 30px;
  font-size: 18px;

  @media screen and (max-width: 980px) {
    margin-top: 30px;
  }
`

export default function FastEasy({
  gridArea,
}) {
  const globalContext = useContext(GlobalContext)


  return (
    <Container gridArea={gridArea}>
      <Sectionheader primary>
        <H2>Global onboarding solution<br />
          for the fintech industry</H2>
        <SectionDescription1>
          With  the help of AI-driven optimization, Checkin.com lets you verify and onboard customers anywhere in the world with ease.
        </SectionDescription1>
      </Sectionheader>

      <FeaturesContainer2>
        <FeaturesColumn>
          <Feature2>
            <ImageComtainer>
              <Verify />
            </ImageComtainer>
            <H4>Verify any ID Document </H4>
            <p>Let customers easily and securely verify their identity in a matter of moments by checking their ID documents. We support 13,000+ ID documents from 190+ countries.</p>
          </Feature2>
          <Feature2>

            <ImageComtainer>
              <Ocr />
            </ImageComtainer>
            <H4>OCR & Data Extraction</H4>
            <p>Collect <a href="/industry-fintech/features/">any data from ID documents</a>. Save your customers’ time by pre-filling online registration forms.</p>
          </Feature2>

        </FeaturesColumn>
        <FeaturesColumn>
          <Feature2>
            <ImageComtainer>
              <Kyc />
            </ImageComtainer>
            <H4>KYC & AML Screening</H4>
            <p>Check customers against all global law enforcement databases, <a href="/product/aml-pep-sanction-checks/">sanctions, PEP, and other sources</a>.</p>
          </Feature2>


          <Feature2>
            <ImageComtainer>
              <Liveness />
            </ImageComtainer>
            <H4>Face matching & liveness</H4>
            <p>Let customers take selfies or perform live actions with their camera to instantly verify it’s a real person that matches their ID.</p>
          </Feature2>
        </FeaturesColumn>
      </FeaturesContainer2>

      <Buttons>
        <Link
          state={{
            modal: true,
          }}
          to={`/industry-fintech/features`} >
          <FeaturesButton>
            See all features
          </FeaturesButton>
        </Link>

        <TalkToSalesButton onClick={() => globalContext.openGetStarted()}>
          Get started
        </TalkToSalesButton>
      </Buttons>


    </Container>
  )
}
