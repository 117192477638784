import React from "react"
import styled from "styled-components"

import SEO from "./seo"

import logoRemote from "../assets/images/connect/tool/logo-remote.svg"

const Container = styled.div`
  display: flex;
  flex: 1;
  background: #f9f9f8;
  flex-direction: column;
  padding: 0 100px;
`

const Header = styled.div`
  width: 100%;
  max-width: 1380px;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
`

const Logo = styled.div`
  width: 81px;
  height: 32px;
  background: url(${logoRemote}) no-repeat center/cover;
`

const Content = styled.div`
  flex: 1;
  max-width: 950px;
  min-width: 950px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

const MobileMessage = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  display: none;
  pointer-events: none;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
  z-index: 3000;

  p {
    text-align: center;
    font-weight: 700;
    line-height: 24px;
  }

  @media screen and (max-width: 640px) {
    display: flex;
    pointer-events: initial;
  }
`

const RemoteToolLayout = ({ children }) => {
  return (
    <Container>
      <SEO
        title="Regily Remote link generator"
        description="Regily Remote link generator"
        canonical="/remote/tool"
      />
      <Header>
        <Logo />
      </Header>
      <Content>{children}</Content>
      <MobileMessage>
        <p>Switch to desktop or a larger device to use this tool.</p>
      </MobileMessage>
    </Container>
  )
}

export default RemoteToolLayout
