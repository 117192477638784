import React from "react"

import Cases from "../assets/images/menu/cases.inline.svg"
import CasesAnim from "../assets/images/menu/cases_anim.svg"
import Airplane from "../assets/images/menu/airplane.inline.svg"
import Game from "../assets/images/menu/game.inline.svg"
import Money from "../assets/images/menu/money.inline.svg"
import Integration from "../assets/images/menu/integration.inline.svg"
import AboutUs from "../assets/images/menu/aboutus.inline.svg"
import Blog from "../assets/images/menu/blog.inline.svg"
import Contacts from "../assets/images/menu/contacts.inline.svg"
import Investor from "../assets/images/menu/investor.inline.svg"
import Career from "../assets/images/menu/career.inline.svg"
import Press from "../assets/images/menu/press.inline.svg"
import AirplaneAnim from "../assets/images/menu/airplane_anim.svg"
import GameAnim from "../assets/images/menu/game_anim.svg"
import MoneyAnim from "../assets/images/menu/money_anim.svg"
import IntegrationAnim from "../assets/images/menu/integration_anim.svg"
import AboutUsAnim from "../assets/images/menu/aboutus_anim.svg"
import BlogAnim from "../assets/images/menu/blog_anim.svg"
import ContactsAnim from "../assets/images/menu/contacts_anim.svg"
import InvestorAnim from "../assets/images/menu/investors_anim.svg"
import CareerAnim from "../assets/images/menu/career_anim.svg"
import PressAnim from "../assets/images/menu/press_anim.svg"
import PartnershipAnim from "../assets/images/menu/partnership_anim.svg"
import Partnership from "../assets/images/menu/partnership.inline.svg"
import Connect from "../assets/images/menu/connect.inline.svg"
import ConnectAnim from "../assets/images/menu/connect_anim.svg"
import Kyc from "../assets/images/menu/kyc.inline.svg"
import KycAnim from "../assets/images/menu/kyc_anim.svg"
import Scanner from "../assets/images/menu/document.inline.svg"
import ScannerAnim from "../assets/images/menu/document_anim.svg"
import Framework from "../assets/images/menu/framework.inline.svg"
import FrameworkAnim from "../assets/images/menu/framework_anim.svg"
import DocScan from "../assets/images/menu/scanning.inline.svg"
import Liveness from "../assets/images/menu/liveness.inline.svg"
import LivenessAnim from "../assets/images/menu/liveness_anim.svg"
import Compliance from "../assets/images/menu/compliance.inline.svg"
import Recognition from "../assets/images/menu/recognition.inline.svg"
import RecognitionAnim from "../assets/images/menu/recognition_anim.svg"
import Poa from "../assets/images/menu/poa.inline.svg"
import PoaAnim from "../assets/images/menu/poa_anim.svg"
import Botlens from "../assets/images/menu/botlens.inline.svg"
import BotlensAnim from "../assets/images/menu/botlens_anim.svg"
import Signature from "../assets/images/menu/signature.inline.svg"
import SignatureAnim from "../assets/images/menu/signature_anim.svg"
import Onboarding from "../assets/images/menu/onboarding.inline.svg"
import OnboardingAnim from "../assets/images/menu/onboarding_anim.svg"

const Menu = [
    {
        title: 'Product',
        gridTemlplateCol: "1fr 1fr",
        width: '630px',
        items: [
            {
                logo: <DocScan />,
                logoHover: (<img style={{ width: '42px' }} src={ScannerAnim} />),
                title: 'Document scanning',
                text: 'Identity verification via ID scanning',
                link: '/id-scanner'
            },
            {
                logo: <Compliance />,
                logoHover: (<img style={{ width: '37px' }} src={KycAnim} />),
                title: 'KYC & AML Compliance',
                text: 'Stay compliant with regulations',
                link: '/product/aml-pep-sanction-checks/'

            },
            {
                logo: <Liveness />,
                logoHover: (<img style={{ width: '35px' }} src={LivenessAnim} />),
                title: 'Liveness',
                text: 'Identity fraud, deep fakes',
                link: '/product/liveness'
            },
            {
                logo: <Botlens />,
                logoHover: (<img style={{ width: '40px', marginLeft: "-1px" }} src={BotlensAnim} />),
                title: 'Botlens',
                text: 'Discern real humans from bots',
                link: '/botlens'
            },
            {
                logo: <Recognition />,
                logoHover: (<img style={{ width: '40px' }} src={RecognitionAnim} />),
                title: 'Face recognition',
                text: 'Face similarity 1:1 and 1:N',
                link: '/product/face-recognition'
            },
            {
                logo: <Poa />,
                logoHover: (<img style={{ width: '40px' }} src={PoaAnim} />),
                title: 'Proof of address',
                text: 'Confirm the user\'s place of permanent residence',
                link: '/product/proof-of-address'
            },
            {
                logo: <Onboarding />,
                logoHover: (<img style={{ width: '38px', marginLeft: '-2px' }} src={OnboardingAnim} />),
                title: 'User onboarding',
                text: 'Framework for optimized sign-ups',
                link: '/user-onboarding'
            },
            {
                logo: <Signature />,
                logoHover: (<img style={{ width: '34px', marginBottom: "4px" }} src={SignatureAnim} />),
                title: 'Signature',
                text: 'Comparison of signature interactions 1-1 and 1-N',
                link: '/product/signature'
            },


        ]
    },
    {
        title: 'Industries',
        gridTemlplateCol: "1fr",
        width: '190px',
        items:
            [
                {
                    logo: <Game />,
                    logoHover: (<img src={GameAnim} />),
                    title: 'iGaming',
                    link: '/industry-igaming'

                },
                {
                    logo: <Airplane />,
                    logoHover: (<img src={AirplaneAnim} />),
                    title: 'Travel',
                    link: '/industry-travel'
                },
                {
                    logo: <Money />,
                    logoHover: (<img src={MoneyAnim} />),
                    title: 'Finance/Crypto',
                    link: '/industry-fintech'
                }
            ]
    },

    {
        title: 'Resources',
        gridTemlplateCol: "1fr",
        width: '190px',
        items:
            [
                {
                    logo: <Integration />,
                    logoHover: (<img src={IntegrationAnim} />),
                    title: 'Integration',
                    outsider: true,
                    link: 'https://dev.checkin.com/docs/home'
                },
                {
                    logo: <Blog />,
                    logoHover: (<img src={BlogAnim} />),
                    title: 'Blog',
                    link: '/blog'
                },
                {
                    logo: <Cases />,
                    logoHover: (<img src={CasesAnim} />),
                    title: 'Case studies',
                    link: '/case-studies'
                },
            ]
    },

    {
        title: 'Company',
        gridTemlplateCol: "1fr",
        width: '217px',
        items:
            [
                {
                    logo: <Partnership />,
                    logoHover: (<img src={PartnershipAnim} />),
                    title: 'Become a partner',
                    link: '/partnerships'
                },
                {
                    logo: <AboutUs />,
                    logoHover: (<img src={AboutUsAnim} />),
                    title: 'About us',
                    link: '/about-us'
                },
                {
                    logo: <Contacts />,
                    logoHover: (<img src={ContactsAnim} />),
                    title: 'Contacts',
                    link: '/contact-us'
                },
                {
                    logo: <Investor />,
                    logoHover: (<img src={InvestorAnim} />),
                    title: 'Investor relations',
                    outsider: true,
                    link: 'https://group.checkin.com/investors'
                },
                {
                    logo: <Press />,
                    logoHover: (<img src={PressAnim} />),
                    title: 'Press and media',
                    outsider: true,
                    link: 'https://group.checkin.com/newsroom'
                },
                {
                    logo: <Career />,
                    logoHover: (<img src={CareerAnim} />),
                    title: 'Careers',
                    outsider: true,
                    link: 'https://group.checkin.com/careers/'
                },
            ]
    }
]

export default Menu
