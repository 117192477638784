import React, { useEffect, useState, useRef, useContext } from "react"
import styled, { css, keyframes } from "styled-components"
import PropTypes from "prop-types"
import { useSelector, useDispatch } from "react-redux"
import { rgba } from "polished"
import { Cookies, getCookieConsentValue } from "react-cookie-consent";
import { Script } from "gatsby"
import loadable from '@loadable/component'

import {
  detectDevice,
  setWindowHeight,
  trackCompanyInfo,
  pushCustomDataLayerEvent,
  trackBehaviorInfo,
} from "../state/actions"
import GlobalStyle from "./global-style"
import NavBar from "./nav-bar"
import FullScreenNav from "./full-screen-nav"
import DashboardLayout from "./dashboard-layout"
import RemoteToolLayout from "./remote-tool-layout"
import getUrlParam from "../utils/get-url-param"
import newsTemplates from "../news-templates.json"
import Footer from "../components/footer_grid"
import FooterEmpty from "../components/footer_empty"
import CalendarLayout from "./calendar-layout"
import IntegrationLayout from "./integration-layout"
import closeMenuIcon from "../assets/images/close-menu-icon.svg"
import CloseChatIcon from "../assets/images/close-chat-icon.svg"
import chatIcon from "../assets/images/chat-icon.svg"
import Cookie from "../assets/images/coockie.svg"
import GlobalContext from "../context/global-context"

const CookieConsent = loadable(() => import("react-cookie-consent"))

const GreyIn = keyframes`
  from {
    background: transparent;
  }

  to {
    background: ${rgba("grey", 0.9)};

  }
`
const Collapse = keyframes`
  from {
    height: 300px;
  }

  to {
    height: 0px;

  }
`

const GreyOut = keyframes`
  from {
    background: ${rgba("grey", 0.9)};
  }

  to {
    background: transparent;
  }
`

const LayoutContainer = styled.div`

.consent-container {
  @media screen and (max-width: 980px) {
    padding: 13px 16px !important;
  }
}

#hs-chat-widget-container .consent-container {
  flex-direction: column;
  height: 100%;

  .content-container {
    margin-bottom: 30px !important;
    text-align: center;
  }
}

.content-container {
  a {
    text-decoration-line: underline; 
  }
  margin-bottom: 0px !important;

  @media screen and (max-width: 640px) {
    margin-bottom: 24px !important;
  }
}

.button-wrapper {
  @media screen and (max-width: 640px) {
  display: flex;
  justify-content: space-between;
  width: 100%;
  }
}

p a {
  text-decoration: underline;
  text-decoration-color: #c3c3c3;
}

#hubspot-messages-iframe-container {
    position: absolute !important;
  }
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 500px;
  flex: 1;
  background: ${props => props.color};

  &::after {
    content: "";
    position: fixed;
    height: 300px;
    width: 100%;
    top: 0px;
    background: ${props => props.color};
    z-index: -1;
  }
`

const Tag = styled.div`
  font-size: 10px;
  font-weight: 700;
  background: #769fed;
  text-transform: uppercase;
  color: #fff;
  padding: 3px 6px;
  margin-right: 10px;
`

const NewsBox = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background: #fff;
  border-top: 1px dashed #000;
  z-index: 3000;
  box-sizing: border-box;
  transform: translate3d(0, 0%, 0);
  transition: transform 1s ease;

  @media screen and (min-width: 641px) {
    display: none;
  }

  p {
    font-size: 13px;
  }

  ${props =>
    props.hide &&
    css`
      transform: translate3d(0, 100%, 0);
    `}
`

const ChatButtonContainer = styled.div`
  position: fixed;
  bottom: 22px;
  height: 50px;
  right: 22px;
  z-index: 11111111;
`

const ChatButton = styled.button`
  height: 50px;
  width: 50px;
  background: url(${chatIcon}) no-repeat center, #fff;
  box-shadow: none;
  outline: none;
  border: none;
  border: 1px solid #000;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    border: 1px dashed #000;
    top: 6px;
    left: 6px;
    z-index: -1;
    box-sizing: border-box;
  }
  
  &:hover {
    background: url(${chatIcon}) no-repeat center, #c0ebd6;
  }

  ${props =>
    props.chatOpen &&
    css`
      background: url(${CloseChatIcon}) no-repeat center, #000;
      &:hover {
        background: url(${CloseChatIcon}) no-repeat center, #000;
      }
    `}
`

const ChatWidgetContainer = styled.div`
  position: fixed;
  bottom: 10px;
  width: 400px;
  right: 10px;
  height: 500px;
  background: #fff;
  opacity: ${props => (props.chatOpen ? 1 : 0)};
  z-index: 11111;
  pointer-events: ${props => (props.chatOpen ? "all" : "none")};
  transition: opacity 0.2s ease;
  border-radius: 10px;

  ${props =>
    props.isMinimized &&
    css`
      width: 380px;
      left: auto;
      right: 0;
      bottom: 65px;
    `}

  #hubspot-conversations-inline-parent {
    height: 100%;
    width: 100%;
    iframe {
      height: 100%;
      width: 100%;
      border: none;
      border-radius: 10px;
      box-shadow: 0 4px 20px 0 ${rgba("#000", 0.1)};
    }
  }
`

const GreyBackground = styled.div`
display: flex;
justify-content: center;
align-items: center;
position: fixed;
top: 60px;
bottom: 0;
left: 0;
right: 0;
opacity: 1;
z-index: 999;
pointer-events: none;
pointer-events: unset;

&.greyOut{
    animation: ${GreyOut} 0.5s ease forwards 0s, ${Collapse} 0.5s ease forwards 1s;
}

&.greyIn{
  animation: ${GreyIn} 0.5s ease forwards 0s;
  display: block;
 
}
`

const ChatCloseButton = styled.button`
  background: url(${CloseChatIcon}) no-repeat center, transparent;
  outline: none;
  box-shadow: none;
  border: none;
  height: 50px;
  width: 50px;
  position: absolute;
  top: 10px;
  right: 10px;
`

const Layout = ({ children, location, pageContext, ...restProps }) => {
  const dispatch = useDispatch()
  const isMobile = useSelector(state => state.global.isMobile)
  const windowHeight = useSelector(state => state.global.windowHeight)
  const [marketingParams, setMarketingParams] = useState({})
  const [featuredArticleAvailable, setFeaturedArticleAvailable] = useState(
    false
  )
  const [hideNewsBox, setHideNewsBox] = useState(false)
  const [chatOpen, setChatOpen] = useState(false)
  const [showIPOBanner, setShowIPOBanner] = useState(false)
  const [showSubMenu, setShowSubMenu] = useState(false)
  const [removeGreyBackground, setRemoveGreyBackground] = useState(null)
  const [cookieConsentValue, setCookieConsentValue] = useState(getCookieConsentValue("gatsby-gdpr-google-tagmanager"))
  const coockieBannerStyle = {
    background: '#FFF',
    boxShadow: '0px -1px 0px 0px rgba(13, 13, 13, 0.10)',
    display: 'flex',
    padding: '13px 160px',
    justifyContent: 'center',
    alignItems: 'center',
    color: '0D0D0D',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '20px',

    '@media screen and (maxWidth: 980px)': {
      padding: '13px 16px',
    },
  }

  const buttonStyle = {
    height: '48px',
    padding: '0px 40px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: "8px",
    margin: "0 12px",
    border: '1px solid #000',
    background: '#FBD65D',
    boxShadow: '4px 4px 0px 0px rgba(13, 13, 13, 0.10)',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '24px',
    color: '#0D0D0D',
  }

  const declineButtonStyle = {
    ...buttonStyle,
    background: '#fff',
  }



  // const toggleChat = () => {
  //   setChatOpen(v => !v)
  // }

  const showButton = () => {
    return location.pathname.includes('contact-us')
  }

  const openGetStarted = (...args) => {
    window.location.href = '/contact-us';
    // getStartedRef.current && getStartedRef.current.open(...args)
  }

  const toggleIPOBanner = (value) => {
    setShowIPOBanner(value)
  }

  const toggleSubMenu = (value) => {
    setShowSubMenu(value)
  }

  const setCookies = (value) => {
    setCookieConsentValue(value)
  }

  const toggleChat = () => {
    switch (chatOpen) {
      case true:
        setChatOpen(false)
        break

      case false:
        setChatOpen(true)
        break

      default:
        break
    }
  }

  let script = useRef()

  useEffect(() => {
    if (!showSubMenu) {
      setTimeout(() => {
        setRemoveGreyBackground(true)
      }, 500);
    } else {
      setRemoveGreyBackground(false)
    }
  }, [showSubMenu])

  useEffect(() => {
    if (typeof window === "undefined") return

    const excludedPaths = [
      `/ scanning / `,
      `/ guide / `,
      `/ get - started / `,
      `/ publishing / `,
      `/ dashboard / `,
      `/ remote / tool / `,
      `/ blog / `,
      `/ wp - download / `,
      `/ auth / `,
      `/ backoffice / `,
      `/ guide / wp - download / `,
      `/ preview / `,
      `/ start - 2 / `,
      `/ meeting - scheduled / `,
      `/ r / `,
      `/ integration / `,
      `/ calendar / `,
    ]


  }, [location])

  useEffect(() => {
    if (!Cookies.get('gatsby-gdpr-google-tagmanager')) { Cookies.set('gatsby-gdpr-google-tagmanager', 'null'); setCookieConsentValue('null') }
  }, [])

  useEffect(() => {
    if (typeof window === "undefined") return
    dispatch(detectDevice())
    // dispatch(trackCompanyInfo()) disable clearbit
    dispatch(trackBehaviorInfo({}, true))
  }, [dispatch])

  useEffect(() => {
    dispatch(
      pushCustomDataLayerEvent({
        event: "website_first_load",
        eventCategory: "website_first_load",
        eventAction: "load",
        eventLabel: "first_load",
      })
    )
    const [int, kw, cmp] = [
      getUrlParam("int"),
      getUrlParam("kw"),
      getUrlParam("cmp"),
    ]
    setFeaturedArticleAvailable(!!int && !!kw && !!cmp)
    setMarketingParams({
      int,
      kw,
      cmp,
    })

    setTimeout(() => {
      setHideNewsBox(true)
    }, 15000)
  }, [])

  useEffect(() => {
    if (typeof window === "undefined") return
    dispatch(setWindowHeight(window.innerHeight))

    window.onresize = () => {
      if (Math.abs(windowHeight - window.innerHeight) < 200) {
        dispatch(setWindowHeight(window.innerHeight))
      }
    }
  }, [windowHeight, dispatch])

  useEffect(() => {
  }, [])

  useEffect(() => {
    if (isMobile) return
    if (window.HubSpotConversations) {
      if (!window.HubSpotConversations.widget.status().loaded)
        window.HubSpotConversations.widget.load()
    } else {
      window.hsConversationsOnReady = [
        () => {
          if (!window.HubSpotConversations.widget.status().loaded)
            window.HubSpotConversations.widget.load()
        },
      ]
    }

    return () => {
      if (
        window.HubSpotConversations &&
        window.HubSpotConversations.widget.status().loaded
      )
        window.HubSpotConversations.widget.remove()
    }
  }, [isMobile])

  if (pageContext.layout === "dashboard")
    return (
      <DashboardLayout
        location={location}
        pageContext={pageContext}
        {...restProps}
      >
        <GlobalStyle />
        {children}
      </DashboardLayout>
    )

  if (pageContext.layout === "remote-tool")
    return (
      <RemoteToolLayout
        location={location}
        pageContext={pageContext}
        {...restProps}
      >
        <GlobalStyle />
        {children}
      </RemoteToolLayout>
    )

  if (pageContext.layout === "calendar")
    return (
      <CalendarLayout
        location={location}
        pageContext={pageContext}
        {...restProps}
      >
        <GlobalStyle />
        {children}
      </CalendarLayout>
    )

  if (pageContext.layout === "integration")
    return (
      <IntegrationLayout
        location={location}
        pageContext={pageContext}
        {...restProps}
      >
        <GlobalStyle />
        {children}
      </IntegrationLayout>
    )

  if (pageContext.layout === "files")
    return (
      <React.Fragment>
        <GlobalStyle />
        {children}
      </React.Fragment>
    )

  const getBackgroundColor = context => {
    const map = {
      layout: [
        { route: "/", color: "#F9F9F8" },
        { route: "scanning", color: "#F9F9F8" },
        { route: "guide", color: "#F9F9F8" },
        { route: "publishing", color: "#F9F9F8" },
        { route: "beta", color: "#F9F9F8" },
        { route: "/cases/pocket-play", color: "#F9F9F8" },
        { route: "/netherlands", color: "#F2F2F2" },
        { route: "/canada", color: "#F9F9F8" },
        { route: "/meeting-booker-2", color: "#F5F8FA" },
        { route: "/meeting-booker-3", color: "#F5F8FA" },
        { route: "/contact-us", color: "#F9F9F8" },
      ],
      navbar: [
        { route: "/", color: rgba("#f9f9f8", 0.98) },
        { route: "scanning", color: "transparent" },
        { route: "about-us", color: rgba("#f9f9f8", 0.98) },
        { route: "checkin-software", color: rgba("#f9f9f8", 0.98) },
        { route: "/cases/pocket-play", color: rgba("#F9F9F8", 0.98) },
        { route: "/canada", color: rgba("#F9F9F8", 0.98) },
        { route: "/meeting-booker-2", color: "#F5F8FA" },
        { route: "/meeting-booker-3", color: "#f5f8fa" },


      ],
    }

    const result = map[context].find(
      obj => obj.route.indexOf(location.pathname.split("/")[1]) !== -1
    )
    return (result && result.color) || "#f9f9f8"
  }

  const showFooter = () => {
    return ["start", "contact-us", "about-us", "checkin-software", "connect", "industry-igaming", "cases/pocket-play", "netherlands", "id-scanner", "canada", "meeting-booker-2", "industry-travel", "industry-fintech", "product", "meeting-booker-3", "blog", "botlens", "partnership", 'industry-airline', 'north-america', 'user-onboarding'].find(v =>
      location.pathname.includes(v)
    ) || location.pathname == '/'
  }

  return (
    <React.Fragment>
      <GlobalContext.Provider
        value={{
          openGetStarted,
          chatOpen,
          toggleChat,
          showIPOBanner,
          showSubMenu,
          toggleIPOBanner,
          toggleSubMenu,
        }}
      >
        <LayoutContainer
          color={getBackgroundColor("layout")}
          style={
            isMobile
              ? {}
              : {
                minHeight: windowHeight,
                paddingTop: showIPOBanner ? "70px" : 0,
              }
          }
        >
          <GlobalStyle />
          <NavBar
            location={location.pathname}
            color={getBackgroundColor("navbar")}
          />
          {/* <GreyBackground className={showSubMenu ? 'greyIn' : 'greyOut'} remove={removeGreyBackground}></GreyBackground> */}


          {children}
          {!location.pathname.includes("/get-started") &&
            !location.pathname.includes("/meeting-booker") && (
              <FullScreenNav
                location={location.pathname}
                hideOnFullScroll={
                  location.pathname !== "/" || location.pathname !== "/start-2"
                }
              />
            )}

          <ChatWidgetContainer id="hs-chat-widget-container" chatOpen={chatOpen} >
            {cookieConsentValue !== 'true' ?
              <CookieConsent
                disableStyles={true}
                visible={cookieConsentValue === 'null' ? 'show' : cookieConsentValue}
                buttonWrapperClasses="button-wrapper"
                contentClasses="content-container chat-coockie"
                containerClasses='consent-container'
                style={{ ...coockieBannerStyle, padding: '13px 16px' }}
                buttonStyle={{ ...buttonStyle }}
                declineButtonStyle={{ ...declineButtonStyle }}
                contentStyle={{ display: 'flex', alignItems: 'center', margin: '0' }}
                buttonClasses="cookie-accept-button"
                declineButtonClasses="cookie-decline-button"
                enableDeclineButton
                flipButtons
                location="none"
                buttonText="I accept"
                declineButtonText="Decline"
                cookieName="gatsby-gdpr-google-tagmanager"
                onAccept={() => { setCookies('true') }}
                onDecline={() => { setCookies('false', toggleChat()) }}
                ariaAcceptLabel="I accept"
                buttonId="accept"
                declineButtonId="decline"
              >
                <span>Hey there! Ready to chat? Just give us the green light by accepting our cookies! 🍪😊</span>
              </CookieConsent>
              : null
            }
            <ChatCloseButton aria-label="toggle" onClick={() => toggleChat()} />
          </ChatWidgetContainer>


          {(location.pathname === "/" || location.pathname === "/start-2") &&
            featuredArticleAvailable && (
              <NewsBox hide={hideNewsBox}>
                <Tag>News</Tag>
                <p>
                  {newsTemplates.templates[marketingParams.int].headline
                    .replace("[kw]", newsTemplates.kw[marketingParams.kw])
                    .replace("[cmp]", newsTemplates.cmp[marketingParams.cmp])}
                </p>
              </NewsBox>
            )}
          {showFooter() && (
            <Footer location={location} />
          )}
          {location.pathname.includes("meeting-booker-3") && <FooterEmpty location={location} />}
          {cookieConsentValue !== 'true' &&
            <CookieConsent
              visible={cookieConsentValue === 'null' ? 'show' : cookieConsentValue === 'false' ? 'hidden' : 'byCookieValue'}
              buttonWrapperClasses="button-wrapper"
              contentClasses="content-container"
              containerClasses='consent-container'
              style={{ ...coockieBannerStyle }}
              buttonStyle={{ ...buttonStyle }}
              declineButtonStyle={{ ...declineButtonStyle }}
              contentStyle={{ display: 'flex', alignItems: 'center', margin: '0' }}
              buttonClasses="cookie-accept-button"
              declineButtonClasses="cookie-decline-button"
              enableDeclineButton
              flipButtons
              location="bottom"
              buttonText="I accept"
              declineButtonText="Decline"
              cookieName="gatsby-gdpr-google-tagmanager"
              onAccept={() => { setCookies('true') }}
              onDecline={() => { setCookies('false') }}
              ariaAcceptLabel="I accept"
              buttonId="acceptChat"
              declineButtonId="declineChat"
            >
              <img alt="cookie" src={Cookie} width='35px' height="35" /> <span style={{ marginLeft: '16px' }}>By clicking “Accept”, you agree to the use of cookies on <a aria-label="this website" href="/">this website</a>. Read <a href="/cookie-policy">cookie policy</a> </span>
            </CookieConsent>}
          {(cookieConsentValue === 'true') ? <Script src="//js.hs-scripts.com/5855020.js" strategy="idle" /> : null}
        </LayoutContainer>
      </GlobalContext.Provider>
    </React.Fragment >
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.object.isRequired,
}

export default Layout
