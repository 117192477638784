import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({
  description = "",
  lang = "",
  meta = "",
  title = "",
  ogTitle = "",
  ogDescription = "",
  ogImage = "",
  twitterTitle = "",
  twitterDescription = "",
  twitterImage = "",
  canonical = "",
}) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: ogTitle || title,
        },
        {
          property: `og:description`,
          content: ogDescription || metaDescription,
        },
        {
          property: `og:image`,
          content: `${site.siteMetadata.siteUrl}${ogImage} `,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: twitterTitle || title,
        },
        {
          name: `twitter:description`,
          content: twitterDescription || metaDescription,
        },
        {
          name: `twitter:image`,
          content: twitterImage,
        },
        {
          property: `og:image:width`,
          content: "1200",
        },
        {
          property: `og:image:height`,
          content: "627",
        },
        {
          name: `og:url`,
          content: `${site.siteMetadata.siteUrl}${(typeof window !== "undefined" && window.location.pathname) ||
            "/"}`,
        },
      ].concat(meta)}
      link={[
        {
          rel: "canonical",
          href: `${site.siteMetadata.siteUrl}${canonical ||
            (typeof window !== "undefined" && window.location.pathname) ||
            "/"}`,
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "16x16",
          href: "/favicons/icon-16.png",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "32x32",
          href: "/favicons/icon-32.png",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "120x120",
          href: "/favicons/icon-120.png",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "144x144",
          href: "/favicons/icon-144.png",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "180x180",
          href: "/favicons/icon-180.png",
        },
        {
          rel: "icon",
          type: "image/png",
          sizes: "256x256",
          href: "/favicons/icon-256.png",
        },
      ]}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
