import React from "react"
import styled from "styled-components"
import Helmet from "react-helmet"

import RegilyBlackLogo from "../assets/images/regily-black-logo.svg"

const Container = styled.div`
  display: flex;
  flex: 1;
  background: #f9f9f8;
  flex-direction: column;
  padding: 0 100px;
`

const Header = styled.div`
  max-width: 950px;
  min-width: 950px;
  margin: 0 auto;
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
`

const Logo = styled.div`
  width: 79px;
  height: 28px;
  text-indent: -9999px;
  background: url(${RegilyBlackLogo}) no-repeat center/cover;
`

const Content = styled.div`
  flex: 1;
  max-width: 950px;
  min-width: 950px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

const MobileMessage = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  display: none;
  pointer-events: none;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;
  z-index: 3000;

  p {
    text-align: center;
    font-weight: 700;
    line-height: 24px;
  }

  @media screen and (max-width: 640px) {
    display: flex;
    pointer-events: initial;
  }
`

const IntegrationLayout = ({ children }) => {
  return (
    <>
      <Helmet
        meta={[
          {
            name: "robots",
            content: "noindex",
          },
        ]}
      />
      <Container>
        <Header>
          <Logo />
        </Header>
        <Content>{children}</Content>
        <MobileMessage>
          <p>Switch to desktop or a larger device to use this tool.</p>
        </MobileMessage>
      </Container>
    </>
  )
}

export default IntegrationLayout
